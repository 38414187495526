import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BreadcrumbService } from '@ea/services';
import { AccountRoutingModule } from './account-routing.module';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [BreadcrumbService],
})
export class AccountModule {}
